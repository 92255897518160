import React from "react";

import LessonLayout from "~/layouts/lesson";

import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";
import Button from "~/components/button/button";

const Page = () => {
  return (
    <LessonLayout
      current_step="5b"
      lesson={"Lesson 2"}
      color={"hs"}
      total_count={10}
      module_title="Separating Fact from Fiction"
      >
      <ContentWrapper>
        <H2>Learn about Clinical Trials</H2>

        <p>When you click the link to the reading, you will see information on the National Institutes of Health (NIH) website. The NIH is the nation’s medical research agency.</p>
        <p>You do not need to read all the information in this article. <span className="font-bold">Focus on reading the sections that have headings shaded in blue.</span></p>
        <p>You can use the highlighter tool to mark the most important ideas in the reading. Feel free to discuss what should be highlighted with your teammates.</p>
        <p>Click on the following link to learn about what clinical studies are and why they are done.</p>

        <Button
          action="secondary"
          className="m-2"
          color="indigo"
          href="/nih/health-information/nih-clinical-research-trials-you/basics"
          icon="external"
          title="Reading 2"
        >
          Reading 2
        </Button>
      </ContentWrapper>
    </LessonLayout>
  )
}

export default Page;
